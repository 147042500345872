<template>
  <action-button
    ref="action-menu-trigger"
    :class="$style['dropdown']"
    action="multi"
    role="button"
    aria-haspopup="menu"
    :aria-expanded="showMenu ? 'true' : 'false'"
    aria-controls="action-menu-list"
    @click="toggleMenu"
    @keydown.enter.prevent="toggleMenu"
    @keydown.space.prevent="toggleMenu"
    @keydown.esc.prevent="hideMenu($event, true)"
  >
    <ul
      v-if="showMenu"
      id="action-menu-list"
      ref="action-menu-list"
      v-keyboard-nav
      data-testid="action-menu-list"
      class="action-menu"
      role="menu"
      @focusout="hideMenu($event, false)"
    >
      <slot></slot>
    </ul>
  </action-button>
</template>

<script>
import ActionButton from "@/components/ActionButton.vue";

export default {
  name: "ActionMenu",
  components: {
    ActionButton,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    hideMenu(event, focusOnTrigger) {
      if (!this.$refs["action-menu-list"].contains(event.relatedTarget)) {
        this.showMenu = false;
        if (focusOnTrigger) {
          this.$refs["action-menu-trigger"]?.$el?.focus();
        }
      }
    },
  },
};
</script>

<style scoped>
.action-menu {
  position: absolute;
  right: 120%;
  top: 0;
}
</style>

<style lang="scss" module>
@import "@/_variables-legacy.scss";
@import "@/styles/multi-action-dropdown.scss";

.dropdown {
  @include multi-action-dropdown;

  position: relative;
}
</style>
