import { createApiClient } from "@/services/apiClient";
import { downloadFile } from "@/utils";

const baseURL = "v1/users";

export class UserService {
  constructor(authAPIUrl) {
    this.apiClient = createApiClient(authAPIUrl);
  }

  async getUser(id) {
    const rsp = await this.apiClient.get(`${baseURL}/${id}`);
    return {
      success: rsp.data.status === "success",
      data: rsp.data.data,
    };
  }

  async getTeams(id) {
    const rsp = await this.apiClient.get(`${baseURL}/${id}/teams`);
    return {
      success: rsp.data.status === "success",
      data: rsp.data.data,
    };
  }

  async getUsers() {
    const rsp = await this.apiClient.get(`${baseURL}`);
    return {
      success: rsp.data.status === "success",
      data: rsp.data.data.results,
    };
  }

  async createUser(name, email, role, unitId) {
    const rsp = await this.apiClient.post(`${baseURL}`, {
      name: name,
      email: email,
      role: role,
      unit_id: unitId,
    });
    return {
      success: rsp.data.status === "success",
      data: rsp.data.data,
    };
  }

  async updateUser(id, email, name, unitId, role, preferredLanguage) {
    const rsp = await this.apiClient.patch(`${baseURL}/${id}`, {
      name: name,
      email: email,
      role: role,
      unit_id: unitId,
      preferred_language: preferredLanguage,
    });
    return {
      success: rsp.data.status === "success",
      data: rsp.data.data,
    };
  }

  async renameUser(id, name) {
    const rsp = await this.apiClient.patch(`${baseURL}/${id}/name`, {
      name: name,
    });
    return {
      success: rsp.data.status === "success",
    };
  }

  async deleteUser(id) {
    const rsp = await this.apiClient.delete(`${baseURL}/${id}`);
    return {
      success: rsp.data.status === "success",
    };
  }

  async search(email) {
    const rsp = await this.apiClient.get(`${baseURL}/search`, {
      params: { email: email },
    });
    return {
      success: rsp.data.status === "success",
      data: rsp.data.data,
    };
  }

  async changePassword(id, password) {
    const rsp = await this.apiClient.patch(
      `${baseURL}/${id}/password`,
      { password },
      { timeout: 20000 }
    );
    return {
      success: rsp.data.status === "success",
    };
  }

  exportUsers() {
    const path = `/${baseURL}/export`;
    const filepath = this.apiClient.defaults.baseURL + path;

    downloadFile(filepath, "users_export.xlsx");
  }
}
