import { authAPIUrl } from "@/environment";
import { CompanyService } from "@/services/company.service";
import { ProfileService } from "@/services/profile.service";
import { RoleService } from "@/services/role.service";
import { UnitService } from "@/services/unit.service";
import { UserService } from "@/services/user.service";
import { RemoteCaller } from "./remoteCaller";

export class UserHandler {
  constructor(company, getWsSession, apiVersion = 3, isUsingRest = false) {
    this.adminRemote = new RemoteCaller(
      "auth",
      apiVersion,
      company,
      getWsSession,
      "users"
    );
    this.userRemote = new RemoteCaller(
      "authuser",
      apiVersion,
      company,
      getWsSession,
      null
    );
    this.user2Remote = new RemoteCaller(
      "authuser",
      2,
      company,
      getWsSession,
      null
    );
    this.isUsingRest = isUsingRest;
    this.profileService = new ProfileService(authAPIUrl);
    this.userService = new UserService(authAPIUrl);
    this.roleService = new RoleService(authAPIUrl);
    this.companyService = new CompanyService(authAPIUrl, company);
    this.unitService = new UnitService(authAPIUrl);
  }

  createUser(email, name, role, unitId) {
    if (this.isUsingRest) {
      return this.userService.createUser(name, email, role, unitId);
    }
    return this.adminRemote.call("create_user", [email, role], {
      unit_id: unitId,
      name,
    });
  }

  async getUsers(next_page = 0) {
    if (this.isUsingRest) {
      return this.userService.getUsers(next_page);
    }
    const response = await this.adminRemote.call("get_users", [], {
      cursor: next_page,
    });

    return this._transform_response(response);
  }

  async getUser(id) {
    if (this.isUsingRest) {
      return this.userService.getUser(id);
    }

    const response = await this.adminRemote.call("get_user_by_id", [id]);
    return this._transform_response(response);
  }

  getTeamsOfUser(id) {
    if (this.isUsingRest) {
      return this.userService.getTeams(id);
    }
    return this.adminRemote.call("get_teams_of_user", [id]);
  }

  async getRoles() {
    if (this.isUsingRest) {
      const roles = await this.roleService.getRoles();
      return { data: roles.data.results };
    }
    return this.adminRemote.call("get_roles");
  }

  updateUser(id, email, name, unitId, role, additionalProps = {}) {
    if (this.isUsingRest) {
      return this.userService.updateUser(id, email, name, unitId, role);
    }

    return this.adminRemote.call(
      "update_user",
      [id, email, name, unitId, role],
      additionalProps
    );
  }

  renameUser(id, name) {
    if (this.isUsingRest) {
      return this.userService.renameUser(id, name);
    }
    return this.userRemote.call("rename_user", [id, name]);
  }

  changePreferredLanguage(preferredLanguage) {
    if (this.isUsingRest) {
      return this.profileService.changePreferredLanguage(preferredLanguage);
    }
    return this.userRemote.call("change_preferred_language", [
      preferredLanguage,
    ]);
  }

  deleteUser(id) {
    if (this.isUsingRest) {
      return this.userService.deleteUser(id);
    }
    return this.adminRemote.call("delete_user", [id]);
  }

  changePassword(id, email, newPassword) {
    if (this.isUsingRest) {
      return this.userService.changePassword(id, newPassword);
    }
    return this.adminRemote.call("change_password", [email, newPassword]);
  }

  search(email) {
    if (this.isUsingRest) {
      return this.userService.search(email);
    }
    return this.adminRemote.call("search", [email]);
  }

  _transform_response(response) {
    if (typeof response === "object" && "success" in response) {
      return response;
    } else {
      return { success: true, data: response };
    }
  }

  getProfile() {
    if (this.isUsingRest) {
      return this.profileService.getProfile();
    }
    return this.userRemote.call("get_profile");
  }

  requestPasswordResetForCurrentUser() {
    if (this.isUsingRest) {
      return this.profileService.requestPasswordReset();
    }
    return this.userRemote.call("request_password_reset");
  }

  getLicenseDetails() {
    if (this.isUsingRest) {
      return this.companyService.getLicense();
    }
    return this.user2Remote.call("get_license_details");
  }

  addUserToUnit(unitId, userId) {
    if (this.isUsingRest) {
      return this.unitService.addUserToUnit(unitId, userId);
    }
    return this.user2Remote.call("units.add_user", [unitId, userId]);
  }
}
