import { RemoteCaller } from "./remoteCaller";

export class TeamHandler {
  constructor(company, getWsSession, apiVersion = 3) {
    this.remote = new RemoteCaller(
      "auth",
      apiVersion,
      company,
      getWsSession,
      "teams"
    );
    this.userRemote = new RemoteCaller(
      "authuser",
      apiVersion,
      company,
      getWsSession
    );
  }

  createTeam(name, unitId, members, artId) {
    return this.remote.call("create_team", [name], {
      unit_id: unitId,
      members,
      art_id: artId,
    });
  }

  deleteTeam(teamId) {
    return this.remote.call("delete_team", [teamId]);
  }

  archiveTeam(teamId) {
    return this.remote.call("archive_team", [teamId]);
  }

  async getTeams({ unitId, order } = {}) {
    return this.remote.call("get_teams", [], {
      unit_id: unitId,
      order: order,
    });
  }

  updateTeam(teamId, name, unitId, members, artId) {
    return this.remote.call("update_team", [
      teamId,
      name,
      unitId,
      members,
      artId,
    ]);
  }

  updateOidcMapping(teamId, group, role) {
    return this.remote.call("update_oidc_mapping", [teamId, group, role]);
  }

  search(name, unitId) {
    return this.remote.call("search", [name], {
      unit_id: unitId,
    });
  }

  getTeamMembers(teamId) {
    return this.remote.call("get_team_members", [teamId]);
  }

  getTeam(teamId) {
    return this.remote.call("get_team", [teamId]);
  }
}
