<template>
  <div :class="$style['dropdown']">
    <button
      ref="trigger"
      :aria-expanded="isOpen ? 'true' : 'false'"
      :class="$style['dropdown-trigger']"
      @click.stop="toggleDropdown"
    >
      <slot name="trigger" />
    </button>
    <div
      v-show="isOpen"
      ref="dropdownMenu"
      :class="[$style['dropdown-menu'], $style[align]]"
    >
      <div :class="$style['dropdown-content']">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    align: {
      type: String,
      default: "left",
      validator(value) {
        return ["right", "left"].includes(value);
      },
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  created() {
    document.addEventListener("click", this.clickedOutside);
    document.addEventListener("keyup", this.keyPress);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickedOutside);
    document.removeEventListener("keyup", this.keyPress);
  },
  methods: {
    keyPress({ key }) {
      if (this.isOpen && (key === "Escape" || key === "Esc")) {
        this.isOpen = false;
      }
    },
    clickedOutside(event) {
      if (this.isOutsideClick(event.target)) {
        this.isOpen = false;
      }
    },
    isOutsideClick(target) {
      if (target === this.$refs.dropdownMenu) return false;
      if (target === this.$refs.trigger) return false;
      return true;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" module>
@import "@/_variables-legacy.scss";

.dropdown {
  display: inline-flex;
  position: relative;

  .dropdown-trigger {
    display: inherit;
    cursor: pointer;
    outline: none;
    background: none;
    outline: revert;
  }

  .dropdown-menu {
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    min-width: 16rem;
    background-color: $whiteColor;
    z-index: 1;
    margin-top: 0.5rem;

    &.right {
      right: 0;
      left: initial;
    }

    .dropdown-content {
      box-shadow: 0px 2px 16px rgba(5, 0, 56, 0.15);
      padding: 1rem 0;
      border-radius: 10px;
    }
  }
}
</style>

<docs>
Dropdown can be used as a quick menu. 
This component is used in combination with `base-dropdown-item` to define dropdown items.

### Trigger
To pass element which will trigger dropdown use named slot `trigger`.

Avatar as trigger:
  ```jsx
  <base-dropdown>
    <template #trigger>
      <avatar><img src="https://cdn.quasar.dev/img/avatar.png"></avatar>
    </template>
      <base-dropdown-item>Action</base-dropdown-item>
      <base-dropdown-item>Another Action</base-dropdown-item>
      <base-dropdown-item>Click here</base-dropdown-item>
  </base-dropdown>
  ```

Button as trigger:
  ```jsx
  <base-dropdown>
    <template #trigger>
      <base-button class="small">Open Dropdown</base-button>
    </template>
    <base-dropdown-item>Action</base-dropdown-item>
    <base-dropdown-item>Another Action</base-dropdown-item>
    <base-dropdown-item>Click here</base-dropdown-item>
  </base-dropdown>
  ```

### Content
By default content to individual items is passed as text string and it will be automatically rendered as anchor link.
You can pass custom content with `custom` property.

  ```jsx
  <base-dropdown>
    <template #trigger>
      <base-button class="small">Open Dropdown</base-button>
    </template>
    <base-dropdown-item custom><a target="_blank" href="https://www.example.com">Custom link</a></base-dropdown-item>
    <base-dropdown-item custom><a target="_blank" href="https://www.google.com">Another custom link</a></base-dropdown-item>
  </base-dropdown>
  ```

### Examples

</docs>
