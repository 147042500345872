<template>
  <!-- eslint-disable vuejs-accessibility/click-events-have-key-events vuejs-accessibility/no-static-element-interactions -->
  <div
    class="search"
    :class="{ empty: !searchQuery }"
    @click="$refs.input.focus()"
  >
    <div class="inner">
      <div class="input-container">
        <input
          ref="input"
          v-model="searchQuery"
          type="search"
          class="search-input"
          :aria-label="ariaLabel"
          :aria-labelledby="ariaLabelledby"
          :aria-controls="ariaControls"
          :aria-expanded="ariaExpanded"
          :aria-autocomplete="ariaAutocomplete"
          :aria-activedescendant="ariaActivedescendant"
          :role="role"
          :placeholder="placeholder"
          @keydown.enter.prevent="search"
          @focus="focused"
          v-on="$listeners"
        />
      </div>
      <button
        v-if="!immediate"
        title="Search"
        class="icon"
        data-testid="search-button"
        @click.prevent="search"
      >
        <page-loading v-if="loading" />
        <img v-else src="@/assets/search.svg" alt="" />
      </button>
      <img v-else src="@/assets/search.svg" class="icon" alt="" />
    </div>
  </div>
</template>

<script>
import PageLoading from "@/components/PageLoading.vue";

export default {
  name: "SearchBox",
  components: { PageLoading },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    ariaLabelledby: {
      type: String,
    },
    ariaLabel: {
      type: String,
    },
    role: {
      type: String,
    },
    ariaAutocomplete: {
      type: String,
    },
    ariaExpanded: {
      type: String,
    },
    ariaControls: {
      type: String,
    },
    ariaActivedescendant: {
      type: String,
    },
  },
  data() {
    return {
      searchQuery: "",
    };
  },
  watch: {
    searchQuery(val) {
      if (this.immediate || !val) {
        this.$emit("search-value", val);
      }
    },
  },
  methods: {
    focused() {
      if (this.immediate) {
        this.search();
      }
    },
    blur() {
      this.$refs.input.blur();
    },
    search() {
      if (this.immediate || this.searchQuery) {
        this.$emit("search-value", this.searchQuery);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/_colors.scss";
@import "@/_variables-legacy.scss";

.search {
  height: 60px;
  width: 100%;
  border-bottom: 3px solid $darkBlueColor;
  display: flex;
  font-size: 1.25rem;

  button p {
    margin: 0; // for loading indicator
  }

  &.empty {
    border-bottom-color: colors.$grey-neutral-50;
  }

  .inner {
    padding: 0 10px 0 50px;
    width: 100%;
    display: flex;
    align-items: center;

    .input-container {
      width: 100%;

      .search-input {
        color: $darkBlueColor;
        border: none;
        outline: none;
        width: 100%;
        box-sizing: border-box;
        font-size: inherit;

        // Style it like a regular input
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
          display: none;
        }
      }
    }

    .icon {
      width: 30px;
      height: 30px;
      margin-left: 12px;
      background-color: $whiteColor;
      outline: revert;

      img {
        cursor: pointer;
        height: inherit;
        width: inherit;
      }
    }
  }
}
</style>
<docs>
Displays a search box.

**NOTE: Emits a 'search-value' event, to avoid conflicts with the native (but non-standard) 
'search' event https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/search_event

### Examples

Default search on Enter and click on icon.
```vue
<template>
  <search-box placeholder="query" @search-value="search" />
</template>
<script>
export default {
  methods: {
    search(query) {
      alert(`Search for ${query}`);
    }
  }
};
</script>
```

Immediate search.
```vue
<template>
  <div>
    <search-box placeholder="query" immediate @search-value="search" />
    Queries:
    <div v-for="query in queries">{{ query }}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      queries: []
    };
  },
  methods: {
    search(query) {
      this.queries.push(query);
    }
  }
};
</script>
```

</docs>
