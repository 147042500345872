import { createApiClient } from "@/services/apiClient";

export class CompanyService {
  constructor(authAPIUrl, companyID) {
    this.apiClient = createApiClient(authAPIUrl);
    this.companyID = companyID;
  }

  async getLicense() {
    return this.apiClient
      .get(`v1/companies/${this.companyID}/license`)
      .then((rsp) => {
        return {
          data: rsp.data.data,
          success: rsp.data.status === "success",
        };
      });
  }

  async getPasswordRules() {
    return this.apiClient
      .get(`v1/companies/${this.companyID}/password-rules`)
      .then((rsp) => {
        return {
          data: rsp.data.data,
          success: rsp.data.status === "success",
        };
      });
  }

  async getTheme() {
    return this.apiClient
      .get(`v1/companies/${this.companyID}/theme`)
      .then((rsp) => {
        return {
          data: rsp.data.data.name,
          success: rsp.data.status === "success",
        };
      });
  }

  async setTheme(theme_name) {
    return this.apiClient
      .patch(`v1/companies/${this.companyID}/theme`, { name: theme_name })
      .then((rsp) => {
        return {
          success: rsp.data.status === "success",
        };
      });
  }
}
