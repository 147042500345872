<template>
  <div class="main-section">
    <h2 class="h-column-center fs-extra-large">Units</h2>
    <message-box v-if="isEmpty()" type="warning">
      Only use Units if you exactly know what you are doing and what you want to
      accomplish. If you want to learn more about it visit our
      <a
        href="https://rentouch.atlassian.net/l/c/mDsorfks"
        target="_blank"
        class="link"
      >
        documentation</a
      >.
    </message-box>
    <message-box v-else>
      As a company or unit admin you can create Units to separate affiliated
      companies, company divisions, ARTs, etc. Before you do anything in here
      you may consult your company admin first.
    </message-box>
    <unit-tree
      ref="tree"
      :root="tree"
      :show-root="!isEmpty()"
      :editable-units="true"
      @changed="fetchUnitTree"
    >
      <div v-if="!isEmpty()" class="col-2 header" :class="{ high: isEmpty() }">
        Edit
      </div>
      <div v-if="!isEmpty()" class="col-1 header" :class="{ high: isEmpty() }">
        Action
      </div>
    </unit-tree>
    <div class="buttons">
      <base-button @click="createUnit"> Create new Unit </base-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import unitTree from "./unitTree/unitTree";
import { sortChildren } from "./unitTree/treeFunctions";
import { UnitHandler } from "@/handlers/unitHandler";
import MessageBox from "@/components/MessageBox";
import BaseButton from "@/components/BaseButton.vue";
import { isFeatureEnabled } from "@/feature";

export default {
  name: "UnitsPage",
  components: {
    unitTree,
    MessageBox,
    BaseButton,
  },
  data() {
    return {
      tree: {},
    };
  },
  computed: {
    ...mapGetters(["company", "session"]),
    isRestApiEnabled() {
      return isFeatureEnabled(this.$route, "rest-api");
    },
    unitHandler() {
      return new UnitHandler(
        this.company,
        this.session,
        3,
        this.isRestApiEnabled
      );
    },
  },
  mounted() {
    this.fetchUnitTree();
  },
  methods: {
    fetchUnitTree() {
      this.unitHandler.getUnitTree().then(({ data: tree }) => {
        sortChildren(tree);
        this.tree = tree;
      });
    },
    isEmpty() {
      return (
        !this.tree || !this.tree.children || this.tree.children.length === 0
      );
    },
    createUnit() {
      this.$refs.tree.show("editUnitModal");
    },
  },
};
</script>

<style lang="scss">
@import "@/_variables-legacy.scss";

.text-input {
  border-color: $darkBlueColor;
  font-size: 1.25rem;
}

.buttons {
  margin-top: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
</style>
