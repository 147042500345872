<template>
  <input
    :id="id"
    v-model="inputValue"
    :class="{
      [$style.input]: true,
      [$style.empty]: !inputValue,
      [$style['with-errors']]: showErrors,
    }"
    :type="type"
    @invalid="$emit('invalid', $event)"
    v-on="listeners"
  />
</template>

<script>
export default {
  props: {
    /**
     * The value of the input. Used in a v-model
     */
    value: {
      type: [String, Number],
      default: "",
    },
    /**
     * The type of input. Supported types are 'text', 'number', 'email', 'tel'
     */
    type: {
      type: String,
      default: "text",
    },
    /**
     * Controls whether the input should be highlighted in red if it's invalid.
     * Can be used to prevent showing validation before the input has been
     * modified.
     */
    showErrors: {
      type: Boolean,
      default: true,
    },
    /**
     * The id of the input. Should be associated with a label element.
     */
    id: {
      type: String,
      required: false,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    listeners() {
      const listeners = { ...this.$listeners };
      delete listeners.input;
      delete listeners.invalid;
      return listeners;
    },
  },
  methods: {
    checkValidity() {
      return this.$el.checkValidity();
    },
    reportValidity() {
      return this.$el.reportValidity();
    },
    setCustomValidity(message) {
      return this.$el.setCustomValidity(message);
    },
  },
};
</script>

<style lang="scss" module>
@use "@/_colors.scss";
@import "@/_variables-legacy.scss";

.input {
  color: $darkBlueColor;
  border: none;
  border-bottom: 3px solid $darkBlueColor;
  height: 60px;
  font-size: 1.5rem;
  padding-left: 50px;
  box-sizing: border-box;
  outline: 0;

  &.empty {
    border-bottom-color: colors.$grey-neutral-50;
  }

  &[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
  }

  &[type="number"]::-webkit-inner-spin-button,
  &[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &:invalid {
    box-shadow: none;
  }

  &.with-errors:invalid {
    box-shadow: none;
    border-bottom: 3px solid $redColor;
    color: $redColor;
  }
}
</style>

<docs>
A basic input for forms

### Examples

With a placeholder:
```vue
<template>
  <base-input id="tel" placeholder="Telephone" v-model="tel" />
</template>
<script>
export default {
  data() {
    return { tel: '' };
  }
}
</script>
```

It can be used in conjuctions with `v-model`:

```vue
<template>
  <div>
    Hello, {{ name }}!
    <div>
      <label for="name">Name:</label>
      <base-input id="name" v-model="name" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "Jim"
    };
  }
};
</script>
```

With validation errors:

```vue
<template>
  <div>
    <base-input
      id="name"
      v-model="name"
      label="Name"
      ref="nameInput"
      required
    />
  </div>
</template>
<script>

export default {
  data() {
    return {
      name: "",
    };
  },
  async mounted() {
    this.$refs.nameInput.checkValidity();
  }
};
</script>
```

With a different type:
```jsx
<div style="display: grid; grid: repeat(3, 1fr) / 1fr 3fr; align-items: end;">
  <label for="email">E-mail:</label>
  <base-input type="email" />
  <label for="number">Number:</label>
  <base-input type="number" />
  <label for="tel">Tel:</label>
  <base-input type="tel" />
</div>
```

Only display validation errors once the form has been modified:

```vue
<template>
  <base-input v-model="email" type="email" :show-errors="showErrors" @input="showErrors = true" />
</template>
<script>
export default {
  data() {
    return { email: 'invalid', showErrors: false}
  }
}
</script>
```

Manually trigger an error from an external operation:

```vue
<template>
  <div>
    <base-input v-model="areaPath" ref="myInput" />
    <base-button @click="$refs.myInput.setCustomValidity('Area path is invalid')">Set error</base-button>
  </div>
</template>
<script>
export default {
  data() {
    return { areaPath: 'something\\invalid' };
  },
}
</script>
```

</docs>
