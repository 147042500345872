<template>
  <base-button
    class="skip-button"
    :secondary="true"
    tabindex="0"
    v-on="$listeners"
  >
    <slot />
  </base-button>
</template>

<script>
import BaseButton from "@/components/BaseButton.vue";

export default {
  components: {
    BaseButton,
  },
};
</script>

<docs>
    Button that is only displayed to keyboard users (hidden until
    it's tabbed to).
  
    ### Examples

    Add small button with label:
    ```jsx
    <skip-button>Skip to Main Content</skip-button>
    ```
  </docs>

<style lang="scss">
@use "@/colors";

.skip-button {
  position: fixed;
  top: 20px;
  left: 20px;
  font-size: 1.2rem;
  padding: 0.5rem 1rem !important;
  opacity: 0;
  z-index: -1;

  &:focus,
  :focus-within {
    z-index: 999;
    opacity: 1;
  }

  &:focus-visible {
    box-shadow: inset 0 0 0 1px colors.$blue-light, 0 0 0 1px colors.$blue,
      0 0 0 5px colors.$blue-light;
  }
}
</style>
