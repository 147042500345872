export const appLoaded = () => ({
  event: "web.app_loaded",
});

// user click on a link in the sidebar
export const sidebarItemClicked = (item) => ({
  event: "web.sidebar.item_clicked",
  item,
});

// user click on prepare new PI button
export const safepiplanningsNewButtonClicked = () => ({
  event: "web.safepiplannings_page.prepare_new_button_clicked",
});

// user clicks on the action menu edit button
export const safepiplanningsEditSessionButtonClicked = (trigger) => ({
  event: "web.safepiplannings_page.session_action_menu_edit",
  ...(trigger ? { trigger } : {}),
});

// user duplicates a session using the duplicate modal in the Safepiplannings Page
export const safepiplanningsDuplicateModalOptionSelected = (
  withIterations
) => ({
  event: "web.safepiplannings_page.duplicate_modal_option_selected",
  withIterations,
});

// user sees the duplicate session modal
export const safepiplanningsDuplicateModalSeen = () => ({
  event: "web.safepiplannings_page.duplicate_modal_seen",
});

// user clicks on the action menu import button
export const safepiplanningsImportModalSeen = (modal) => ({
  event: "web.safepiplannings_page.import_modal_seen",
  modal,
});

// user clicks on the action menu xlsx download button
export const safepiplanningsXlsxDownloadClicked = () => ({
  event: "web.safepiplannings_page.session_action_menu_xlsx_download",
});

// user clicks on the action menu delete button
export const safepiplanningsSessionDeleteClicked = () => ({
  event: "web.safepiplannings_page.session_action_menu_delete",
});
// user clicks on the action menu archive button
export const safepiplanningsSessionArchiveClicked = () => ({
  event: "web.safepiplannings_page.session_action_menu_archive",
});

// users sees the "New Session" page
export const newPiplanningPageSeen = () => ({
  event: "web.new_piplanning.page_seen",
});

// user completes a steap in the "New Session" page
export const newPiplanningStepCompleted = (step) => ({
  event: "web.new_piplanning.step_completed",
  step,
});

// user finish adding a new session
export const newPiplanningCompleted = () => ({
  event: "web.new_piplanning.completed",
});

//  use clicks on the "Create new User" button on the Users page
export const usersPageCreateUserClicked = () => ({
  event: "web.users_page.create_user_button_clicked",
});

// when the users crates a new user on the "Create User" page
export const userCreated = (role) => ({
  event: "web.create_user_page.user_created",
  role,
});

// user clicks on the "Create new Team" button on the Teams tab
// on the Organization page
export const teamsPageCreateTeamButtonClicked = () => ({
  event: "web.teams_page.create_new_button_clicked",
});

// user clicks on the "Buy Licenses" button on the Teams tab
// on the Organization page
export const buyLicensesClicked = () => ({
  event: "web.teams_page.buy_licenses_clicked",
});

// when a new team is created by the user on the "Create Team" page
export const teamCreated = (withUserInvites) => ({
  event: "web.create_team_page.team_created",
  withUserInvites,
});

// user clicks on the "Create ART" button on the ARTs tab
// on the Organization page
export const artsPageCreateNewClicked = () => ({
  event: "web.arts_page.create_new_button_clicked",
});

// when a new ART is created by the user on the "Create ART" page
export const artCreated = () => ({
  event: "web.create_art_page.art_created",
});

// user clicks on the "Create Solution Train" button on the
// Solution Trains tab on the Organization page
export const solutionTrainsCreateNewClicked = () => ({
  event: "web.solution_trains_page.create_new_button_clicked",
});

// when a new Solution Train is created by the user on the
// "Create Solution Train" page
export const solutionTrainCreated = () => ({
  event: "web.create_solution_train_page.solution_train_created",
});

// user clicks on the "Add new ALM connection" button
// on the ALM connections page
export const almConnectionsPageAddNewButtonClicked = () => ({
  event: "web.alm_connections_page.create_new_button_clicked",
});

// the user sees the "ALM Connections" page
export const almConnectionsPageSeen = () => ({
  event: "web.alm_connections_page.page_seen",
});

// the user clicks on the "Add new ALM connection" button
// on the ALM Connections page
export const almConnectionsCreateNewClicked = () => ({
  event: "web.alm_connections_page.create_new_button_clicked",
});

// when an ALM tool is succesfully connected
export const almConnectionCreated = (alm) => ({
  event: "web.create_alm_connections_page.connection_created",
  alm,
});

// triggered when the user chooses an ALM tool and clicks on next on
// the "Create new ALM connection" page
export const createAlmConnectionsConnectToAlmStarted = (alm) => ({
  event: "web.create_alm_connections_page.connect_started",
  alm,
});
