import SafePiPlanningsPage from "@/pages/authRequired/SafePiPlanningsPage";
import AlmConnectionsPage from "@/pages/authRequired/almConnections/AlmConnectionsPage.vue";
import AddAlmConnection from "@/pages/authRequired/almConnections/AddAlmConnection";
import SettingsPage from "@/pages/authRequired/SettingsPage";
import ProfilePage from "@/pages/authRequired/users/ProfilePage";
import UnitsPage from "@/pages/authRequired/UnitsPage";
import UnauthorizedPage from "@/pages/authRequired/additionalPages/UnauthorizedPage.vue";
import TrainingBillingPage from "@/pages/authRequired/TrainingBillingPage";
import { getPublicPagesRoutes } from "@/router/publicPages";
import { reloadOnMissingChunks } from "@/utils";
import { appDemoSessionWelcomeUrl } from "@/utils/url";
import LogoutPage from "@/pages/LogoutPage.vue";

const AddNewSafePiPlanningPage = () =>
  import(
    /* webpackChunkName: "AddNewSafePiPlanningPage" */ "@/pages/authRequired/AddNewSafePiPlanningPage"
  ).catch(reloadOnMissingChunks);
const editSession = () =>
  import(
    /* webpackChunkName: "editSession" */ "@/pages/authRequired/sessions/editSession"
  ).catch(reloadOnMissingChunks);
const SelectIdentityProvider = () =>
  import(
    /* webpackChunkName: "SelectIdentityProvider" */ "@/pages/authRequired/idp/SelectIdentityProvider"
  ).catch(reloadOnMissingChunks);
const IdentityProvider = () =>
  import(
    /* webpackChunkName: "IdentityProvider" */ "@/pages/authRequired/idp/IdentityProvider"
  ).catch(reloadOnMissingChunks);
const SetupOIDC = () =>
  import(
    /* webpackChunkName: "SetupOIDC" */ "@/pages/authRequired/idp/SetupOIDC"
  ).catch(reloadOnMissingChunks);
const SetupSAMLv2 = () =>
  import(
    /* webpackChunkName: "SetupSAMLv2" */ "@/pages/authRequired/idp/SetupSAMLv2"
  ).catch(reloadOnMissingChunks);
const EditTeam = () =>
  import(
    /* webpackChunkName: "EditTeam" */ "@/pages/authRequired/organization/EditTeam.vue"
  ).catch(reloadOnMissingChunks);
const EditUser = () =>
  import(
    /* webpackChunkName: "EditUser" */ "@/pages/authRequired/users/EditUser.vue"
  ).catch(reloadOnMissingChunks);
const TeamsList = () =>
  import(
    /* webpackChunkName: "TeamsList" */ "@/pages/authRequired/organization/TeamsList.vue"
  ).catch(reloadOnMissingChunks);
const UsersPage = () =>
  import(
    /* webpackChunkName: "UsersPage" */ "@/pages/authRequired/users/UsersPage.vue"
  ).catch(reloadOnMissingChunks);
const BillingPage = () =>
  import(
    /* webpackChunkName: "BillingPage" */ "@/pages/authRequired/BillingPage"
  ).catch(reloadOnMissingChunks);
const SelectOptions = () =>
  import(
    /* webpackChunkName: "SelectOptions" */ "@/pages/authRequired/billing/SelectOptions"
  ).catch(reloadOnMissingChunks);
const ExtendLicenses = () =>
  import(
    /* webpackChunkName: "ExtendLicenses" */ "@/pages/authRequired/billing/ExtendLicenses"
  ).catch(reloadOnMissingChunks);
const BuyLicenses = () =>
  import(
    /* webpackChunkName: "BuyLicenses" */ "@/pages/authRequired/billing/BuyLicenses"
  ).catch(reloadOnMissingChunks);
const UpgradeLicenses = () =>
  import(
    /* webpackChunkName: "UpgradeLicenses" */ "@/pages/authRequired/billing/Upgrade"
  ).catch(reloadOnMissingChunks);
const Invoice = () =>
  import(
    /* webpackChunkName: "Invoice" */ "@/pages/authRequired/billing/Invoice"
  ).catch(reloadOnMissingChunks);
const EditAlmConnection = () =>
  import(
    /* webpackChunkName: "EditAlmConnection" */ "@/pages/authRequired/almConnections/EditAlmConnection.vue"
  ).catch(reloadOnMissingChunks);
const AlmConnection = () =>
  import(
    /* webpackChunkName: "AlmConnection" */ "@/pages/authRequired/almConnections/AlmConnection.vue"
  ).catch(reloadOnMissingChunks);
const AlmWebhook = () =>
  import(
    /* webpackChunkName: "AlmWebhook" */ "@/pages/authRequired/almConnections/AlmWebhook.vue"
  ).catch(reloadOnMissingChunks);
const AlmTeamMapping = () =>
  import(
    /* webpackChunkName: "AlmTeamMapping" */ "@/pages/authRequired/almConnections/AlmTeamMapping.vue"
  ).catch(reloadOnMissingChunks);
const acWorkspace = () =>
  import(
    /* webpackChunkName: "acWorkspace" */ "@/components/almToolConfig/ac/acWorkspace.vue"
  ).catch(reloadOnMissingChunks);
const adoProject = () =>
  import(
    /* webpackChunkName: "adoProject" */ "@/components/almToolConfig/ado/adoProject.vue"
  ).catch(reloadOnMissingChunks);
const AlmTeam = () =>
  import(
    /* webpackChunkName: "AlmTeam" */ "@/pages/authRequired/almConnections/AlmTeam.vue"
  ).catch(reloadOnMissingChunks);
const AlmArtMapping = () =>
  import(
    /* webpackChunkName: "AlmArtMapping" */ "@/pages/authRequired/almConnections/AlmArtMapping.vue"
  ).catch(reloadOnMissingChunks);
const AlmArt = () =>
  import(
    /* webpackChunkName: "AlmArt" */ "@/pages/authRequired/almConnections/AlmArt.vue"
  ).catch(reloadOnMissingChunks);
const AlmEvents = () =>
  import(
    /* webpackChunkName: "AlmEvents" */ "@/pages/authRequired/almConnections/AlmEventsPage.vue"
  ).catch(reloadOnMissingChunks);
const OrganizationPage = () =>
  import(
    /* webpackChunkName: "OrganizationPage" */ "@/pages/authRequired/organization/OrganizationPage.vue"
  ).catch(reloadOnMissingChunks);
const ArtsList = () =>
  import(
    /* webpackChunkName: "ArtsList" */ "@/pages/authRequired/organization/ArtsList.vue"
  ).catch(reloadOnMissingChunks);
const EditArt = () =>
  import(
    /* webpackChunkName: "EditArt" */ "@/pages/authRequired/organization/EditArt.vue"
  ).catch(reloadOnMissingChunks);
const SolutionTrainsList = () =>
  import(
    /* webpackChunkName: "SolutionTrainsList" */ "@/pages/authRequired/organization/SolutionTrainsList.vue"
  ).catch(reloadOnMissingChunks);
const EditSolutionTrain = () =>
  import(
    /* webpackChunkName: "EditSolutionTrain" */ "@/pages/authRequired/organization/EditSolutionTrain.vue"
  ).catch(reloadOnMissingChunks);

export default {
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 10);
    });
  },
  routes: [
    ...getPublicPagesRoutes(),
    {
      path: "/registered",
      name: "registered",
      beforeEnter() {
        sessionStorage.setItem("redirect", appDemoSessionWelcomeUrl());
        window.location = "registered.html";
      },
      meta: { isCloud: true, title: "Registered" },
    },
    {
      path: "/billing",
      component: BillingPage,
      meta: {
        roles: ["admin"],
        isCloud: true,
        training: false,
        title: "Billing",
      },
      children: [
        {
          path: "",
          name: "billing",
          component: SelectOptions,
          meta: {
            roles: ["admin"],
            isCloud: true,
            training: false,
            title: "Billing",
          },
        },
        {
          path: "extend",
          component: ExtendLicenses,
          meta: {
            roles: ["admin"],
            isCloud: true,
            training: false,
            title: "Billing, Extend licenses",
          },
        },
        {
          path: "buyMore",
          component: BuyLicenses,
          meta: {
            roles: ["admin"],
            isCloud: true,
            training: false,
            title: "Billing, Buy more licenses",
          },
        },
        {
          path: "upgrade",
          component: UpgradeLicenses,
          meta: {
            roles: ["admin"],
            isCloud: true,
            training: false,
            title: "Billing, Upgrade plan",
          },
        },
        {
          path: "invoice",
          component: Invoice,
          meta: {
            roles: ["admin"],
            isCloud: true,
            training: false,
            title: "Billing, Pay by invoice",
          },
        },
      ],
    },
    {
      path: "/trainingbilling",
      name: "trainingBilling",
      component: TrainingBillingPage,
      meta: {
        roles: ["admin"],
        isCloud: true,
        training: true,
        title: "Training Billing",
      },
    },
    {
      path: "/learn",
      name: "learn",
      beforeEnter: () => {
        window.open("https://www.piplanning.io/learn");
      },
    },
    {
      path: "/users",
      name: "users",
      component: UsersPage,
      meta: { roles: ["admin"], onPrem: true, default: 2, title: "Users" },
    },
    {
      path: "/users/new",
      name: "createUser",
      component: EditUser,
      props: { title: "Create User" },
      meta: {
        roles: ["admin"],
        onPrem: true,
        backLink: { name: "users" },
        title: "Create User",
      },
    },
    {
      path: "/users/:id",
      name: "editUser",
      component: EditUser,
      props: (route) => ({ id: route.params.id, title: "Edit User" }),
      meta: {
        roles: ["admin"],
        onPrem: true,
        title: "Edit User",
        backLink: { name: "users" },
      },
    },
    {
      path: "/organization",
      name: "organization",
      component: OrganizationPage,
      meta: { roles: ["admin", "planning_interval_admin"] },
      redirect: { name: "teams" },
      children: [
        {
          path: "teams",
          name: "teams",
          component: TeamsList,
          meta: { roles: ["admin", "planning_interval_admin"], title: "Teams" },
        },
        {
          path: "teams/:id",
          name: "editTeam",
          component: EditTeam,
          props: (route) => ({ id: route.params.id }),
          meta: {
            roles: ["admin", "planning_interval_admin"],
            title: "Edit Team",
            backLink: { name: "teams" },
          },
        },
        {
          path: "teams/new",
          name: "createTeam",
          component: EditTeam,
          meta: {
            roles: ["admin", "planning_interval_admin"],
            title: "Create Team",
            backLink: { name: "teams" },
          },
        },
        {
          path: "arts",
          name: "arts",
          component: ArtsList,
          meta: {
            roles: ["admin", "planning_interval_admin"],
            title: "Agile Release Trains",
          },
        },
        {
          path: "arts/new",
          name: "createArt",
          component: EditArt,
          meta: {
            roles: ["admin"],
            title: "Create ART",
            backLink: { name: "arts" },
          },
        },
        {
          path: "arts/:id",
          name: "editArt",
          component: EditArt,
          props: (route) => ({ artId: route.params.id }),
          meta: {
            roles: ["admin", "planning_interval_admin"],
            title: "Edit ART",
            backLink: { name: "arts" },
          },
        },
        {
          path: "solution_trains",
          name: "solution_trains",
          component: SolutionTrainsList,
          meta: { roles: ["admin"], title: "Solution Trains" },
        },
        {
          path: "solution_trains/new",
          name: "createSolutionTrain",
          component: EditSolutionTrain,
          meta: {
            roles: ["admin"],
            title: "Create Solution Train",
            backLink: { name: "solution_trains" },
          },
        },
        {
          path: "solution_trains/:id",
          name: "editSolutionTrain",
          component: EditSolutionTrain,
          props: (route) => ({ solutionTrainId: route.params.id }),
          meta: {
            roles: ["admin"],
            title: "Edit Solution Train",
            backLink: { name: "solution_trains" },
          },
        },
      ],
    },
    {
      path: "/units",
      name: "units",
      component: UnitsPage,
      meta: { roles: ["admin"], training: false, title: "Units" },
    },
    {
      path: "/safepiplannings",
      name: "safepiplannings",
      component: SafePiPlanningsPage,
      meta: {
        roles: ["admin", "planning_interval_admin"],
        default: 1,
        title: "SAFe PIs",
      },
    },
    {
      path: "/add-safepiplanning",
      name: "addnewsafepiplanning",
      component: AddNewSafePiPlanningPage,
      meta: {
        roles: ["admin", "planning_interval_admin"],
        title: "Add new SAFe PI",
      },
    },
    {
      path: "/edit-session",
      name: "editSession",
      component: editSession,
      meta: {
        roles: ["admin", "planning_interval_admin"],
        title: "Edit PI",
      },
    },
    {
      path: "/alm-connections",
      name: "almConnections",
      component: AlmConnectionsPage,
      meta: { roles: ["admin"], title: "ALM Connections" },
    },
    {
      path: "/alm-connections/new",
      name: "AddAlmConnection",
      component: AddAlmConnection,
      meta: { roles: ["admin"], title: "Add ALM Connection" },
    },
    {
      path: "/alm-connections/:almType/:connectionId",
      component: EditAlmConnection,
      meta: { roles: ["admin"], title: "Edit ALM Connection" },
      children: [
        {
          path: "",
          name: "editAlmConnection",
          component: AlmConnection,
          props: true,
          meta: { roles: ["admin"], title: "Edit ALM Connection" },
        },
        {
          path: "webhook",
          name: "editAlmWebhook",
          component: AlmWebhook,
          props: true,
          meta: { roles: ["admin"], title: "Edit ALM Webhook" },
        },
        {
          path: "teams",
          name: "editAlmTeamMapping",
          component: AlmTeamMapping,
          props: true,
          meta: { roles: ["admin"], title: "Edit ALM Team Mapping" },
        },
        {
          path: "teams/:teamId",
          name: "editAlmTeam",
          component: AlmTeam,
          props: true,
          meta: { roles: ["admin"], title: "Edit ALM Team" },
        },
        {
          path: "arts",
          name: "editAlmArtMapping",
          component: AlmArtMapping,
          props: true,
          meta: { roles: ["admin"], title: "Edit ALM ART Mapping" },
        },
        {
          path: "arts/:artId",
          name: "editAlmArt",
          component: AlmArt,
          props: true,
          meta: { roles: ["admin"], title: "Edit ALM ART" },
        },
        {
          path: "workspace",
          name: "editAlmWorkspace",
          component: acWorkspace,
          props: true,
          meta: { roles: ["admin"], title: "Edit ALM Workspace" },
        },
        {
          path: "project",
          name: "editAlmProject",
          component: adoProject,
          props: true,
          meta: { roles: ["admin"], title: "Edit ALM Project" },
        },
        {
          path: "events",
          name: "almConnectionEvents",
          component: AlmEvents,
          props: true,
          meta: { roles: ["admin"], title: "ALM Connection Events" },
        },
      ],
    },
    {
      path: "/idp",
      component: IdentityProvider,
      meta: {
        roles: ["admin"],
        onPrem: true,
        isSSO: true,
        title: "Identity Provider",
      },
      children: [
        {
          path: "",
          name: "SelectIdentityProvider",
          component: SelectIdentityProvider,
          meta: {
            roles: ["admin"],
            onPrem: true,
            isSSO: true,
            title: "Select Identity Provider",
          },
        },
        {
          path: "saml",
          name: "SetupSAMLv2",
          component: SetupSAMLv2,
          meta: {
            roles: ["admin"],
            onPrem: true,
            isSSO: true,
            title: "Setup SAML v2",
          },
        },
        {
          path: "oidc",
          name: "SetupOIDC",
          component: SetupOIDC,
          meta: {
            roles: ["admin"],
            onPrem: true,
            isSSO: true,
            title: "Setup OIDC",
          },
        },
      ],
    },
    {
      path: "/settings",
      name: "settings",
      component: SettingsPage,
      meta: { roles: ["admin"], title: "Settings" },
    },
    {
      path: "/profile",
      name: "profile",
      component: ProfilePage,
      meta: {
        roles: ["user", "admin", "planning_interval_admin", "observer"],
        default: 3,
        title: "Profile",
      },
    },
    {
      path: "/logout",
      name: "logout",
      component: LogoutPage,
    },
    {
      path: "/403",
      name: "403",
      component: UnauthorizedPage,
      meta: { hideMenu: true, title: "ERROR: 403 Forbidden" },
    },
    {
      path: "*",
      redirect: "safepiplannings",
    },
  ],
  mode: "history",
};
