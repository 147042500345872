import Vue from "vue";
import Vuex from "vuex";

import { toastStoreConf } from "./toast.js";

Vue.use(Vuex);

export function getStoreConf() {
  return {
    modules: {
      toast: toastStoreConf,
    },
    state: {
      crossNotAvailable: false,
      expireDays: null,
      onPrem: false,
      wsconnection: {},
      userInfo: {},
      authServerErr: "",
      startedPageState: "default",
      stateManager: [],
      isSSOEnabled: false,
      order: {},
      hasUnits: false,
      license: {},
    },
    getters: {
      company(state) {
        if (!state.userInfo || !state.userInfo.authid) {
          return undefined;
        }
        const authid = state.userInfo.authid;
        return authid && authid.split(".")[0];
      },
      username(state) {
        const authid = state.userInfo.authid;
        return authid && authid.split(".")[1];
      },
      isAdmin(state) {
        return state.userInfo.authrole === "admin";
      },
      isPlanningIntervalAdmin(state) {
        return state.userInfo.authrole === "planning_interval_admin";
      },
      isTraining(state, getters) {
        return getters.company && getters.company.startsWith("pips-");
      },
      session(state) {
        return () => {
          if (!state.wsconnection.session?.isOpen) {
            throw new Error("Autobahn session is not open");
          }
          return state.wsconnection.session;
        };
      },
      isRetryingConnection(state) {
        return state.wsconnection?.isRetrying;
      },
    },
    mutations: {
      license: (state, license) => {
        state.license = license;
      },
      notAvailable(state) {
        state.crossNotAvailable = true;
      },
      available(state) {
        state.crossNotAvailable = false;
      },
      expireDays(state, days) {
        state.expireDays = days;
      },
      onPrem(state, onPrem) {
        state.onPrem = onPrem;
      },
      enableSSO(state) {
        state.isSSOEnabled = true;
      },
      disableSSO(state) {
        state.isSSOEnabled = false;
      },
      setUserInfo(state, info) {
        state.userInfo = { authid: info.authid, authrole: info.authrole };
      },
      setConnection(state, connection) {
        state.wsconnection = connection;
      },
      closeConnection(state) {
        state.wsconnection.close("wamp.close.logout");
        state.expireDays = null;
      },
      setServerError(state, err) {
        state.authServerErr = err;
      },
      setRegisterBtnState(state, scope) {
        scope.context.hold = scope.isLocked;
        scope.context.setSubmitText(scope.message);
      },
      /***** current state  *****/
      setStartedPageState(state, newState) {
        store.commit("setCurrentState", state.startedPageState);
        store.commit("setState", newState);
      },
      setState(state, newState) {
        state.startedPageState = newState;
        scrollTop();
        localStorage.setItem("currentState", JSON.stringify(newState));
      },
      setCurrentState(state, lastState) {
        store.commit("setStateManager", lastState);
        localStorage.setItem("stateStack", JSON.stringify(state.stateManager));
      },
      setStateManager(state, newState) {
        if (typeof newState === "object") {
          state.stateManager = newState;
        } else {
          state.stateManager.push(newState);
        }
      },
      getPreviousState(state) {
        store.commit("setState", state.stateManager.pop());
        localStorage.setItem("stateStack", JSON.stringify(state.stateManager));
      },
      clearStateManager(state) {
        state.stateManager = [];
        state.startedPageState = "default";
        localStorage.removeItem("stateStack");
        localStorage.removeItem("currentState");
      },
      order(
        state,
        { company, price, numberOfLicenses, validityStartDate, validityEndDate }
      ) {
        state.order = {
          company,
          price,
          numberOfLicenses,
          validityStartDate,
          validityEndDate,
        };
      },
      hasUnits(state, hasUnits) {
        state.hasUnits = hasUnits;
      },
    },
  };
}

const store = new Vuex.Store(getStoreConf());

function scrollTop() {
  $("html, body").animate(
    {
      scrollTop: 0,
    },
    200
  );
}

export default store;
