<template>
  <transition-group
    :class="$style['notifications-list']"
    tag="ul"
    name="toast-list"
  >
    <li v-for="{ id, message, type } in messages" :key="id || message">
      <message-box
        :type="type"
        :role="'alert' /* JAWS doesn't always announce 'status' */"
        :class="[$style.notification, { [$style.error]: isError(type) }]"
        >{{ message }}</message-box
      >
    </li>
  </transition-group>
</template>

<script>
import { mapState } from "vuex";
import MessageBox from "@/components/MessageBox";

export default {
  components: {
    MessageBox,
  },
  computed: {
    ...mapState("toast", ["messages"]),
  },
  methods: {
    isError(type) {
      return type === "error";
    },
  },
};
</script>

<style lang="scss" module>
@import "@/_variables-legacy.scss";

.notifications-list {
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  max-height: 100vh;
  min-width: 650px;
  position: fixed;
  right: 0;
  top: 6%;
  transition: all 1s;
  z-index: 20;

  li {
    flex: 0;
    margin: 0.5rem;
    transition: all 1s;
  }

  .notification {
    width: 512px;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.18);
    box-sizing: initial;
    background-color: $whiteColor;
    border-left: 8px solid $greenColor;

    &.error {
      border-left: 8px solid $redColor;
    }
  }
}
</style>

<style lang="scss">
.toast-list-enter,
.toast-list-leave-to {
  opacity: 0;
}

.toast-list-enter {
  translate: 50%;
}

.toast-list-leave-to {
  translate: 0 -100%;
}
</style>

<docs>
  ### Examples

  This is a generic notification area that is displayed in the top right corner
  of the screen. It can be used to display success and error messages by using
  the Vuex store from any component, and the message will be displayed if the
  Vue component is included somewhere in the application.

  ```vue
  <template>
    <div>
      <toast-notifications />
      <button @click="successMessage">Show success message</button>
      <button @click="errorMessage">Show error message</button>
    </div>
  </template>
  <script>
  import { mapActions } from 'vuex';

  export default {
    methods: {
      ...mapActions('toast', ['showMessage']),
      successMessage() {
        this.showMessage({ message: "This is a success message." });
      },
      errorMessage() {
        this.showMessage({ message: "Something wrong happened.", type: "error" });
      }
    }
  }
  </script>
  ```

  It is also possible to use promises to display a feedback:

  ```vue
  <template>
    <div>
      <button @click="successMessage">Show success message</button>
      <button @click="errorMessage">Show error message</button>
    </div>
  </template>

  <script>
  import { mapActions } from 'vuex';

  export default {
    methods: {
      ...mapActions('toast', ['showFeedback']),
      successMessage() {
        const promise = Promise.resolve("A successful action");
        this.showFeedback({ action: promise, successMessage: "Action completed with great success." });
      },
      errorMessage() {
        const promise = Promise.reject("Something wrong happened while processing the action.");
        this.showFeedback({ action: promise });
      }
    }
  }
  </script>
  ```
</docs>
