import VueFormGenerator from "vue-form-generator";
import VueUploadComponent from "vue-upload-component";
import VueKindergarten from "vue-kindergarten";

import CommonData from "./common-data.js";
import AlmHandler from "./handlers/almHandler";
import { formatCurrency, formatDate, capitalize } from "./utils/filters";
import VueGtag from "vue-gtag";
import { isCloud, gaKey } from "@/environment";
import { autofocus } from "@/directives/autofocus";
import { focusTrap } from "@/directives/focus-trap";
import { keyboardNavigation } from "@/directives/keyboardNavigation";

export function initializeGlobalVue(vue) {
  vue.use(VueFormGenerator);
  vue.use(CommonData);
  vue.use(AlmHandler);
  vue.use(VueKindergarten, {});
  vue.use(VueGtag, {
    disableScriptLoad: !isCloud,
    bootstrap: false,
    config: { id: gaKey },
    enabled: !!gaKey,
  });

  vue.directive("autofocus", autofocus());
  vue.directive("focus-trap", focusTrap());
  vue.directive("keyboard-nav", keyboardNavigation());

  vue.component("FileUpload", VueUploadComponent);

  vue.filter("date", formatDate);
  vue.filter("currency", formatCurrency);
  vue.filter("capitalize", capitalize);
}
