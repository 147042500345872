import SessionHandler from "@/handlers/sessionHandler";

export { CompanyHandler } from "@/handlers/companyHandler";
export { IdentityProviderHandler } from "@/handlers/identityProviderHandler";
export { LicenseHandler } from "@/handlers/licenseHandler";
export { SessionsHandler } from "@/handlers/sessionsHandler";
export { TeamHandler } from "@/handlers/teamHandler";
export { UnitHandler } from "@/handlers/unitHandler";
export { UserHandler } from "@/handlers/userHandler";

/**
 * @deprecated Instantiate the handler directly in components as computed property.
 * Pass session and company from store inside component file.
 */
export function handlerFromStore(
  handler,
  store,
  apiVersion,
  isUsingRest = false
) {
  if (handler === SessionHandler) {
    throw new Error(
      "SessionHandler must be instanciated with sessionHandlerFromStore"
    );
  }
  return new handler(
    store.getters.company,
    store.getters.session,
    apiVersion,
    isUsingRest
  );
}
/**
 * @deprecated Instantiate SessionHandler directly in components as computed property.
 * Pass session and company from store inside component file.
 */
export function sessionHandlerFromStore(sessionID, store, apiVersion) {
  return new SessionHandler(
    sessionID,
    store.getters.company,
    store.getters.session,
    apiVersion
  );
}
