import axios from "axios";
import { v4 as uuid } from "uuid";

export function createApiClient(baseUrl, options) {
  const client = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    timeout: 10000,
    transformRequest: (data, headers) => {
      if (headers) {
        headers["common"]["correlation-id"] = uuid();
      }
      for (const transform of axios.defaults.transformRequest) {
        data = transform(data, headers);
      }
      return data;
    },
    ...options,
  });

  client.interceptors.response.use(
    (response) => {
      if (
        (response.data.success && !response.data.success) ||
        (response.data.status && response.data.status !== "success")
      ) {
        throw new Error(response.data.error.message);
      }
      return response;
    },
    (error) => {
      console.log(error);
      if (error.data?.error.message) {
        Promise.reject(error.data.error.message);
      }
      Promise.reject(error);
    }
  );

  return client;
}

export function createOldApiClient(baseUrl, options) {
  const client = axios.create({
    baseURL: baseUrl,
    withCredentials: true,
    timeout: 10000,
    transformRequest: (data, headers) => {
      if (headers) {
        headers["common"]["correlation-id"] = uuid();
      }
      for (const transform of axios.defaults.transformRequest) {
        data = transform(data, headers);
      }
      return data;
    },
    ...options,
  });
  return client;
}
